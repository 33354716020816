import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MenuPage from './MenuPage'; // Import the MenuPage component
import './App.css';

// Import the menu images for each menu
import menu from './hooperchillmenu.jpg';
import menu2 from './hooperchillmenu2.jpg';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MenuPage imageSrc={menu} />} />
        <Route path="/drink" element={<MenuPage imageSrc={menu2} />} />
      </Routes>
    </Router>
  );
}

export default App;
