import React from 'react';

function MenuPage({ imageSrc }) {
  return (
    <>
      <div className="App">
        <img src={imageSrc} className="App-menu" alt="menu" />
      </div>
    </>
  );
}

export default MenuPage;
